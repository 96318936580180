export const theme = {
  colors: {
    successGreen: "#7ac142",
    lightPurple: "#9F3EF5",
    purple: "#58099E",
    softGray: "#f8f8f8",
    easyGray: "#efefef",
    lightGray: "#CCCCCC",
    gray: "#3E3E3E",
    black: "#000000",
    white: "#ffffff",
    red: "#ef4035",
    gradient: "linear-gradient(45deg, #9013FE 0%, #9F3EF5 100%)"
  },
  breakPoints: {
    base: 0,
    small: 576,
    medium: 768,
    large: 992,
    extraLarge: 1200
  } as Breakpoints<number>
} as const

export type BreakpointTypes = "base" | "small" | "medium" | "large" | "extraLarge"
export type Breakpoints<T> = { [key in BreakpointTypes]: T }
