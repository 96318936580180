import Heading from "../Heading/Heading"
import { Link } from "react-router-dom"
import classNames from "classnames"

interface RaceCardProps {
  background?: "default" | "brand"
  description: React.ReactNode
  highlighted?: boolean
  large?: boolean
  lead?: boolean
  mail?: boolean
  openInNewWindow?: boolean
  simple?: boolean
  title?: React.ReactNode
  to: string
}

const raceCardRowBackgroundBrandClasses = classNames(
  `border-none bg-brand-primary text-white after:border-white hover:bg-brand-secondary hover:after:border-white dark:bg-brand-secondary hover:dark:bg-brand-primary`
)
const raceCardRowBackgroundDefaultClasses = classNames(`bg-transparent after:border-gray-400 hover:bg-black/5 dark:hover:bg-slate-950`)
const raceCardRowClasses = classNames(`
  relative flex min-h-16 flex-col justify-center border-b border-gray-100 py-2 pl-4 pr-8 no-underline transition-colors duration-200 ease-in-out after:absolute after:right-4 after:top-2/4 after:h-4 after:w-4 after:-translate-y-2/4 after:rotate-45 after:border-r after:border-t after:opacity-40 after:transition-all after:duration-200 after:ease-in-out after:content-[""] hover:after:opacity-100 dark:border-gray-100/10 dark:after:opacity-20 dark:hover:after:opacity-100`)
const raceCardRowHighlightedClasses = classNames(`md:pl-8`)
const raceCardRowSimpleClasses = classNames(`min-h-12 after:h-3 after:w-3`)

export default function RaceCardRow({ background = "default", title, description, to, lead, large, highlighted, mail, openInNewWindow, simple }: RaceCardProps) {
  if (!!mail) {
    return (
      <a
        className={classNames(
          raceCardRowClasses,
          highlighted && raceCardRowHighlightedClasses,
          simple && raceCardRowSimpleClasses,
          background === "default" && raceCardRowBackgroundDefaultClasses,
          background === "brand" && raceCardRowBackgroundBrandClasses
        )}
        href={`mailto:${to}`}
      >
        {title && (
          <Heading type={lead || large ? "h2" : "h3"} size={(lead && "h1") || (large && "h3") || "h5"} brand={large || lead}>
            {title}
          </Heading>
        )}
        {description}
      </a>
    )
  }
  return (
    <Link
      className={classNames(
        raceCardRowClasses,
        highlighted && raceCardRowHighlightedClasses,
        simple && raceCardRowSimpleClasses,
        background === "default" && raceCardRowBackgroundDefaultClasses,
        background === "brand" && raceCardRowBackgroundBrandClasses
      )}
      to={to}
      rel={openInNewWindow ? "noopener noreferrer" : ""}
      target={openInNewWindow ? "_blank" : "_self"}
    >
      {title && (
        <Heading type={lead || large ? "h2" : "h3"} size={(lead && "h1") || (large && "h3") || "h5"} brand={large || lead}>
          {title}
        </Heading>
      )}
      {description}
    </Link>
  )
}
