import { Cloudinary, type Transformation } from "cloudinary-core"
import { cloudinaryCloudName } from "./cloudinaryConfig"
import { images } from "@website/layout/images"

export const getImageUrlByPublicIdAndWidth = (publicId: string, width: number): string => {
  const options: Partial<Transformation.Options> = {
    width: width,
    crop: "fill",
    quality: 80
  }

  return getImageUrlByPublicId(publicId, options)
}

export const getOpenGraphImageUrlByPublicId = (publicId: string | null): string | null => {
  if (!publicId) {
    return images.ogRaceFallback
  }
  const options: Partial<Transformation.Options> = {
    width: 1200,
    gravity: "face",
    crop: "fill",
    fetchFormat: "jpg",
    quality: 80
  }

  return getImageUrlByPublicId(publicId, options)
}

export const getImageUrlByPublicId = (publicId: string, options?: Partial<Transformation.Options>): string => {
  const cloudinary = new Cloudinary({ cloud_name: cloudinaryCloudName, secure: true })
  return cloudinary.url(publicId, options)
}
