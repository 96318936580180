import { theme, type BreakpointTypes } from "../layout/styles"
import { type Transformation } from "cloudinary-core"
import { getImageUrlByPublicId } from "@infrastructure/cloudinary/cloudinaryUtils"

interface Rule {
  breakPoint: BreakpointTypes
  imageWidth: number
  sizeWidthValue?: string
}

interface SrcSetAndSizes {
  src: string
  sizes: string
  srcSet: string
}

interface Options {
  rules: Rule[]
  default: BreakpointTypes
  transforms?: Partial<Transformation.Options>
}

export const cloudinarySrcSet = (publicId: string, { rules, default: defaultBreakpoint, transforms }: Options): SrcSetAndSizes => {
  return rules.reduce(
    (acc, item) => {
      const size = `(min-width: ${theme.breakPoints[item.breakPoint]}px) ${item.sizeWidthValue ?? `${item.imageWidth}px`}`
      const imageUrl = getImageUrlByPublicId(publicId, { ...transforms, fetchFormat: "auto", width: item.imageWidth })
      const srcSet = `${imageUrl} ${item.imageWidth}w`
      return {
        sizes: `${acc.sizes ? `${acc.sizes}, ` : ""}${size}`,
        srcSet: `${acc.srcSet ? `${acc.srcSet}, ` : ""}${srcSet}`,
        src: item.breakPoint === defaultBreakpoint ? imageUrl : acc.src
      } as SrcSetAndSizes
    },
    {
      src: "",
      sizes: "",
      srcSet: ""
    }
  )
}
