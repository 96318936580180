import { CardImage } from "@website/components/Card/Card"
import { cloudinarySrcSet } from "../cloudinarySrcSet"
import placeholder from "@website/home/placeholder-small.svg"

interface RaceCardImageProps {
  openInNewWindow?: boolean
  to: string
  heroImagePublicId?: string | null
  raceName: string
}

export default function RaceCardImage({ openInNewWindow = false, to, heroImagePublicId, raceName }: RaceCardImageProps) {
  return heroImagePublicId ? (
    <CardImage
      to={to}
      openInNewWindow={openInNewWindow}
      alt={raceName}
      loading="lazy"
      {...cloudinarySrcSet(heroImagePublicId, {
        rules: [{ breakPoint: "base", imageWidth: 700 }],
        default: "base",
        transforms: { quality: 90, crop: "fill", height: 280, gravity: "face" }
      })}
    />
  ) : (
    <CardImage to={to} alt={raceName} loading="lazy" src={placeholder} />
  )
}
